import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import "./App.css";
import GameDetails from "./GameDetails";
import UserProfile from "./UserProfile";
import Leaderboard from "./Leaderboard";
import Chat from "./Chat";
import { AuthContext } from "./contexts/AuthContext";
import Predictions from "./Predictions";
import NavBar from "./NavBar";
import Rules from "./Rules";
import Footer from "./Footer";

export {
  formatGameTime,
  formatGameName,
  getCountdown,
  getUTC2Date,
  renderSpreadCell,
};

console.log(process.env.REACT_APP_API_URL);

function renderSpreadCell(game, prediction) {
  if (!prediction.spread_prediction) {
    return "";
  }
  if (prediction.spread_prediction === prediction.team1) {
    return game.spread > 0
      ? `${prediction.spread_prediction} (+${game.spread})`
      : `${prediction.spread_prediction} (${game.spread})`;
  } else if (prediction.spread_prediction === prediction.team2) {
    return game.spread > 0
      ? `${prediction.spread_prediction} (-${game.spread})`
      : `${prediction.spread_prediction} (+${Math.abs(game.spread)})`;
  }

  return "";
}

function formatGameName(game) {
  const gameName =
    game.score1 !== null && game.score2 !== null
      ? `${game.team1} ${game.emoji1} ${game.score1}-${game.score2} ${game.emoji2} ${game.team2}`
      : `${game.team1} ${game.emoji1} vs. ${game.emoji2} ${game.team2}`;

  return (
    <b>
      <Link to={`/game/${game.id}`}>{gameName}</Link>
    </b>
  );
}

function formatGameTime(dateTimeString) {
  const gameDate = new Date(dateTimeString);

  const utcDate = new Date(gameDate.getTime() - 2 * 60 * 60 * 1000);

  return utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  });
}

function getUTC2Date() {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours() + 2,
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );
}

function getCountdown(targetDate) {
  const now = getUTC2Date();
  const gameTime = new Date(targetDate);
  const distance = gameTime - now;

  if (distance < -2 * 60 * 60 * 1000) {
    return "Awaiting result";
  } else if (distance < 0) {
    return "Game in progress";
  } else {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    return `(In ${days}d ${hours}h ${minutes}m)`;
  }
}

function App() {
  const [games, setGames] = useState([]);
  const [predictions, setPredictions] = useState({});
  const [rounds, setRounds] = useState([]);
  const [currentRound, setCurrentRound] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { user, login, logout } = useContext(AuthContext);
  const [tournamentId, setTournamentId] = useState(1);
  const [tournaments, setTournaments] = useState([]);

  const fetchTournaments = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tournaments`
      );
      if (!response.ok) throw new Error("Failed to fetch tournaments");
      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        throw new Error("Invalid or empty tournament data received");
      }
      return data;
    } catch (error) {
      return [];
    }
  }, []);

  const initializeTournament = useCallback((availableTournaments) => {
    const storedId = localStorage.getItem("tournamentId");
    if (
      storedId &&
      availableTournaments.some((t) => t.id.toString() === storedId)
    ) {
      return parseInt(storedId, 10);
    }
    return availableTournaments[0]?.id || null;
  }, []);

  useEffect(() => {
    const initApp = async () => {
      const fetchedTournaments = await fetchTournaments();
      if (fetchedTournaments.length > 0) {
        setTournaments(fetchedTournaments);
        const initialTournamentId = initializeTournament(fetchedTournaments);
        setTournamentId(initialTournamentId);
      } else {
        console.error("No tournaments fetched");
      }
      setIsLoading(false);
    };

    initApp();
  }, [fetchTournaments, initializeTournament]);

  const handleTournamentChange = useCallback(async (newTournamentId) => {
    setTournamentId(newTournamentId);
    localStorage.setItem("tournamentId", newTournamentId.toString());
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      if (user && user.id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/games?tournamentId=${tournamentId}`
          );
          if (!response.ok) throw new Error("Failed to fetch games");
          const gamesData = await response.json();

          const parsedGames = gamesData.map((game) => ({
            ...game,
            spread: game.spread !== null ? parseFloat(game.spread) : null,
            score1: game.score1 !== null ? parseInt(game.score1, 10) : null,
            score2: game.score2 !== null ? parseInt(game.score2, 10) : null,
          }));

          const sortedGames = parsedGames.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );

          setGames(sortedGames);

          const uniqueRounds = [
            ...new Set(sortedGames.map((game) => game.round)),
          ];
          setRounds(uniqueRounds);

          const now = new getUTC2Date();
          const nextGame = sortedGames.find(
            (game) => new Date(game.date) > now
          );
          const nextRound = nextGame
            ? nextGame.round
            : uniqueRounds[uniqueRounds.length - 1];

          setCurrentRound(nextRound);
        } catch (error) {
          console.error("Failed to load games:", error);
        }
      }
    };
    fetchGames();
  }, [user, tournamentId]);

  useEffect(() => {
    const updateCountdowns = () => {
      setCountdowns((prevCountdowns) => {
        const newCountdowns = {};
        games.forEach((game) => {
          const countdown = getCountdown(game.date);
          if (countdown !== prevCountdowns[game.id]) {
            newCountdowns[game.id] = countdown;
          }
        });
        return { ...prevCountdowns, ...newCountdowns };
      });
    };

    updateCountdowns();
    const interval = setInterval(updateCountdowns, 60000);
    return () => clearInterval(interval);
  }, [games]);

  if (isLoading || !tournamentId) {
    return <div>Loading...</div>;
  }

  const currentTournament = tournaments.find((t) => t.id === tournamentId);

  return (
    <Router>
      <div className="App">
        {!isLoading && tournamentId && (
          <NavBar
            user={user}
            login={login}
            logout={logout}
            tournamentId={tournamentId}
            setTournamentId={handleTournamentChange}
            tournaments={tournaments}
          />
        )}
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                currentTournament?.is_over ? (
                  <Navigate to="/leaderboard" replace />
                ) : (
                  <Navigate to="/predictions" replace />
                )
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/predictions"
            element={
              user ? (
                <Predictions
                  user={user}
                  games={games}
                  predictions={predictions}
                  setPredictions={setPredictions}
                  rounds={rounds}
                  currentRound={currentRound}
                  setCurrentRound={setCurrentRound}
                  countdowns={countdowns}
                  tournamentId={tournamentId}
                />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/leaderboard"
            element={(() => {
              return (
                <Leaderboard
                  user={user}
                  tournamentId={tournamentId}
                  tournamentOver={currentTournament?.is_over}
                />
              );
            })()}
          />
          <Route path="/rules" element={<Rules />} />
          <Route
            path="/chat"
            element={
              user ? (
                <Chat user={user} tournamentId={tournamentId} />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/profile"
            element={
              user ? (
                <Navigate to={`/profile/${user.id}`} replace />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/profile/:userId"
            element={
              user ? (
                <UserProfile user={user} tournamentId={tournamentId} />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/game/:id"
            element={<GameDetails user={user} tournamentId={tournamentId} />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
