import React from "react";
import { Tooltip } from "@mui/material";

export const emojiDefinitions = {
  roundWinner: {
    emoji: "🎖️",
    defaultTooltip: "Round Winner",
  },
  onFire: {
    emoji: "🔥",
    defaultTooltip: "On Fire",
  },
  goldMedal: {
    emoji: "🥇",
    defaultTooltip: "Gold Medal",
  },
  silverMedal: {
    emoji: "🥈",
    defaultTooltip: "Silver Medal",
  },
  bronzeMedal: {
    emoji: "🥉",
    defaultTooltip: "Bronze Medal",
  },
  star: {
    emoji: "⭐",
    defaultTooltip: "Category Winner",
  },
  correct: {
    emoji: "✅",
    defaultTooltip: "Correct",
  },
  croissant: {
    emoji: "🥐",
    defaultTooltip: "Croissant King",
  },
  chocolate: {
    emoji: "🍫",
    defaultTooltip: "Anonymous Gift from a Friend",
  },
  // Add more emoji definitions here as needed
};

export const apiEmojiTooltips = {
  "🏆": "Tournament Winner",
  "🎉": "Celebration",
  "🌟": "Star Player",
  "🦄": "Unique Prediction",
  "🍻": "Popular Prediction",
  "🎯": "Perfect Prediction",
  "💤": "0-Point Prediction",
  "😟": "Only 0-Point Prediction",
  "💩": "0-Point Prediction in Double-Point Game",
  "💎": "Perfect Prediction in Double-Point Game",
  "🤯": "Only Prediction to Receive Points",
  // Add more API emoji tooltips here
};

export const flagToCountry = {
  "🇦🇷": "Argentina",
  "🇦🇴": "Angola",
  "🇦🇺": "Australia",
  "🇧🇪": "Belgium",
  "🇧🇦": "Bosnia and Herzegovina",
  "🇧🇷": "Brazil",
  "🇧🇬": "Bulgaria",
  "🇨🇦": "Canada",
  "🇨🇻": "Cape Verde",
  "🇨🇳": "China",
  "🇨🇮": "Ivory Coast",
  "🇭🇷": "Croatia",
  "🇨🇿": "Czech Republic",
  "🇩🇴": "Dominican Republic",
  "🇪🇬": "Egypt",
  "🇪🇪": "Estonia",
  "🇫🇮": "Finland",
  "🇫🇷": "France",
  "🇩🇪": "Germany",
  "🇬🇪": "Georgia",
  "🇬🇧": "Great Britain",
  "🇬🇷": "Greece",
  "🇭🇺": "Hungary",
  "🇮🇷": "Iran",
  "🇮🇱": "Israel",
  "🇮🇹": "Italy",
  "🇯🇵": "Japan",
  "🇯🇴": "Jordan",
  "🇱🇻": "Latvia",
  "🇱🇧": "Lebanon",
  "🇱🇹": "Lithuania",
  "🇲🇽": "Mexico",
  "🇲🇪": "Montenegro",
  "🇳🇱": "Netherlands",
  "🇳🇿": "New Zealand",
  "🇳🇬": "Nigeria",
  "🇵🇭": "Philippines",
  "🇵🇱": "Poland",
  "🇵🇷": "Puerto Rico",
  "🇷🇸": "Serbia",
  "🇸🇰": "Slovakia",
  "🇸🇮": "Slovenia",
  "🇸🇸": "South Sudan",
  "🇪🇸": "Spain",
  "🇹🇷": "Turkey",
  "🇺🇦": "Ukraine",
  "🇺🇸": "United States",
  "🇻🇪": "Venezuela",
};

export const useEmojiTooltip = () => {
  const renderEmojiWithTooltip = React.useCallback(
    ({ emoji, tooltip, count = 1 }) => {
      if (!emoji) return null;
      return (
        <Tooltip title={tooltip} arrow>
          <span style={{ cursor: "pointer" }}>{emoji.repeat(count)}</span>
        </Tooltip>
      );
    },
    []
  );

  const renderPredefinedEmoji = React.useCallback(
    ({ emojiKey, count = 1, customTooltip }) => {
      const emojiData = emojiDefinitions[emojiKey];
      if (!emojiData) return null;
      return renderEmojiWithTooltip({
        emoji: emojiData.emoji,
        tooltip: customTooltip || emojiData.defaultTooltip,
        count,
      });
    },
    [renderEmojiWithTooltip]
  );

  const renderCountryEmojis = React.useCallback((expertCountries) => {
    if (!expertCountries) return null;
    return expertCountries.split(" ").map((flag, index) => (
      <Tooltip key={index} title={flagToCountry[flag] || ""} arrow>
        <span style={{ marginRight: "2px", cursor: "pointer" }}>{flag}</span>
      </Tooltip>
    ));
  }, []);

  const renderApiEmojis = React.useCallback((emojiString) => {
    if (!emojiString) return null;

    // Improved emoji splitting
    const emojis = Array.from(emojiString.trim().matchAll(/\p{Emoji}/gu)).map(
      (m) => m[0]
    );

    return (
      <span aria-label="Emojis">
        {emojis.map((emoji, index) => {
          const tooltip = apiEmojiTooltips[emoji];
          return tooltip ? (
            <Tooltip key={index} title={tooltip} arrow>
              <span
                style={{ cursor: "pointer", marginRight: "2px" }}
                aria-label={tooltip}
              >
                {emoji}
              </span>
            </Tooltip>
          ) : (
            <span key={index} style={{ marginRight: "2px" }} aria-hidden="true">
              {emoji}
            </span>
          );
        })}
      </span>
    );
  }, []);

  return {
    renderEmojiWithTooltip,
    renderPredefinedEmoji,
    renderCountryEmojis,
    renderApiEmojis,
  };
};
