import React from "react";
import { styled } from "@mui/material/styles";

const RulesContainer = styled("div")({
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
  textAlign: "left",
});

const RulesList = styled("ul")({
  paddingLeft: "20px",
  "& li": {
    marginBottom: "10px",
  },
});

function Rules() {
  return (
    <RulesContainer>
      <h1>Game Rules</h1>
      <section>
        <h2>1. General Rules</h2>
        <RulesList>
          <li>
            For every game in the 2024 Paris Olympic Games basketball
            tournament, every user will be expected to make three predictions:
            <ul>
              <li>Winner of the game</li>
              <li>Winner of the game against the spread</li>
              <li>The top scoring player of the game</li>
            </ul>
          </li>
          Example of the spread: if a game is listed as Team A -3.5 vs. Team B +3.5 Team A will have to win by more than 3.5 points and Team B can not lose by more than 3.5 points for the user to score points. Of course,  Team B winning the game outright will score points for the user.
          <br></br>In case of a tie for the top scorer for a game, all tied
          players will score points.
        </RulesList>
      </section>

      <section>
        <h2>2. Scoring</h2>
        <RulesList>
          <li>
            Each correct prediction for the winner, the winner against the spread
            and the top scorer will result in <b>1</b> point for the user.
          </li>
          <li>
            For one game each round, the user can activate the joker card (🃏)
            which will double the points for that game.
          </li>
          <li>All the points in the playoff rounds will be doubled.</li>
          The joker card for the round can be changed as many times as needed
          before the game with the joker card starts. If five out of the six
          games for a round have started but no joker card has been picked yet,
          the user can still use it for the sixth game.
        </RulesList>
      </section>

      <section>
        <h2>3. Fair Play</h2>
        <RulesList>
          <li>
            Every user is expected to behave honorably and with the highest moral
            character inside and outside the game during the tournament. Failure
            to do so will result in disqualification from the game.
          </li>
        </RulesList>
      </section>
      <section>
        <h2>4. Emojis</h2>
        <RulesList>
          You may see all sorts of emojis during the tournament.
          <li>🃏 - Played the joker card for the game</li>
          <li>🎯 - Predicted everything in the game correctly</li>
          <li>💎 - Predicted everything correctly in a joker card game</li>
          <li>💤 - Received 0 points for the game</li>
          <li>💩 - Received 0 points for a joker card game</li>
          <li>🦄 - Made a unique prediction</li>
          <li>🍻 - Made a popular prediction</li>
          <li>🤷 - Made a logically inconsistent prediction</li>
          <li>🤯 - Only user to receive points for the game</li>
          <li>😟 - Only user to not receive points for the game</li>
          <li>🔥 - On fire!</li>
          <li>🇦🇺 🇧🇷 🇨🇦 🇫🇷 🇩🇪 🇬🇷 🇯🇵 🇵🇷 🇷🇸 🇸🇸 🇪🇸 🇺🇸 - is the leading point-getter for selected country</li>
          <li>🎖️ - Won a round</li>
        </RulesList>
      </section>
      <section>
        <h2>5. Croissant Game 🥐</h2>
        <RulesList>
          <li>
            To celebrate the hosts of the Olympic Games, France, during the tournament, any user can send any other user virtual croissants by clicking on the croissant emoji on their profile.
          </li>
        </RulesList>
      </section>

      {/* Add more sections as needed */}
    </RulesContainer>
  );
}

export default Rules;
