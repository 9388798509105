import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";

const NavBar = ({ user, login, logout, tournamentId, setTournamentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTournamentDropdownOpen, setIsTournamentDropdownOpen] =
    useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const tournamentNames = {
    // 5: "🏎️ F1 2025",
    1: "🏀 Paris 2024",
    2: "🏀 World Cup 2023",
    3: "🏀 EuroBasket 2022",
    4: "🏀 Tokyo 2020",
  };

  const mainMenuItems = [
    {
      label: "Predictions",
      path: "/predictions",
      icon: "🔮",
      authRequired: true,
    },
    {
      label: "Leaderboard",
      path: "/leaderboard",
      icon: "🏆",
      authRequired: false,
    },
    { label: "Trash Talk", path: "/chat", icon: "🗣️", authRequired: true },
  ];

  const secondaryMenuItems = [
    { label: "Profile", path: "/profile", icon: "👤", authRequired: true },
    { label: "Rules", path: "/rules", icon: "📜", authRequired: false },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const renderMenuItems = (items) =>
    items.map(
      (item) =>
        (!item.authRequired || user) && (
          <NavLink
            key={item.path}
            to={
              item.path === "/profile" && user
                ? `/profile/${user.id}`
                : item.path
            }
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={toggleMenu}
          >
            <span className="icon">{item.icon}</span> {item.label}
          </NavLink>
        )
    );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsTournamentDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTournamentSelection = (id) => {
    setTournamentId(id);
    setIsTournamentDropdownOpen(false);

    const currentPath = location.pathname;
    
    if (currentPath.startsWith('/game/')) {
      navigate(`/predictions`);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-brand">
            <img src="/logo512.png" alt="Logo" className="navbar-logo" />
            <span className="site-name">playmaker.ee</span>
          </Link>
        </div>
        <div className="navbar-links main-menu">
          <div className="tournament-dropdown" ref={dropdownRef}>
            <button
              onClick={() =>
                setIsTournamentDropdownOpen(!isTournamentDropdownOpen)
              }
              className="tournament-button"
            >
              {tournamentNames[tournamentId] || `Tournament ${tournamentId}`} ▼
            </button>
            {isTournamentDropdownOpen && (
              <div className="tournament-dropdown-menu">
                {Object.entries(tournamentNames).map(([id, name]) => (
                  <button
                    key={id}
                    onClick={() => handleTournamentSelection(Number(id))}
                  >
                    {name}
                  </button>
                ))}
              </div>
            )}
          </div>
          {renderMenuItems(mainMenuItems)}
        </div>
        <div className="navbar-links secondary-menu">
          {renderMenuItems(secondaryMenuItems)}
          <div className="navbar-links main-menu">
            {user ? (
              <button onClick={logout}>
                <span className="icon">🚪</span> Logout
              </button>
            ) : (
              <button onClick={login}>
                <span className="icon">🔑</span> Login
              </button>
            )}
          </div>
        </div>
        <button
          aria-label="Toggle menu"
          className="navbar-toggle"
          onClick={toggleMenu}
        >
          ☰
        </button>
      </div>
      {isOpen && (
        <div className="navbar-mobile">
          <div className="tournament-dropdown" ref={dropdownRef}>
            <button
              onClick={() =>
                setIsTournamentDropdownOpen(!isTournamentDropdownOpen)
              }
              className="tournament-button"
            >
              {tournamentNames[tournamentId] || `Tournament ${tournamentId}`} ▼
            </button>
            {isTournamentDropdownOpen && (
              <div className="tournament-dropdown-menu">
                {Object.entries(tournamentNames).map(([id, name]) => (
                  <button
                    key={id}
                    onClick={() => handleTournamentSelection(Number(id))}
                  >
                    {name}
                  </button>
                ))}
              </div>
            )}
          </div>
          {mainMenuItems.map(
            (item) =>
              (!item.authRequired || user) && (
                <React.Fragment key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={toggleMenu}
                  >
                    <span className="icon">{item.icon}</span> {item.label}
                  </NavLink>
                </React.Fragment>
              )
          )}
          {secondaryMenuItems.map(
            (item) =>
              (!item.authRequired || user) && (
                <NavLink
                  key={item.path}
                  to={
                    item.path === "/profile" && user
                      ? `/profile/${user.id}`
                      : item.path
                  }
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={toggleMenu}
                >
                  <span className="icon">{item.icon}</span> {item.label}
                </NavLink>
              )
          )}
          {user ? (
            <button
              onClick={() => {
                logout();
                toggleMenu();
              }}
            >
              <span className="icon">🚪</span> Logout
            </button>
          ) : (
            <button
              onClick={() => {
                login();
                toggleMenu();
              }}
            >
              <span className="icon">🔑</span> Login
            </button>
          )}
        </div>
      )}{" "}
      <style jsx>{`
        .tournament-dropdown {
          position: relative;
          display: inline-block;
          margin-left: 10px;
        }
        .tournament-button {
          background: none;
          border: none;
          background-color: #007bff;
          color: white;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 4px;
          font-family: "Titillium Web";
        }
        .tournament-dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          display: flex;
          flex-direction: column;
        }
        .tournament-dropdown-menu button {
          display: block;
          width: 100%;
          text-align: left;
          padding: 8px 12px;
          border: none;
          background: none;
          cursor: pointer;
          font-family: "Titillium Web";
          white-space: nowrap;
        }
        .tournament-dropdown-menu button:hover {
          background-color: transparent;
        }
        .tournament-dropdown-menu button:focus {
          outline: none;
        }
      `}</style>
    </nav>
  );
};

export default NavBar;
