import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useEmojiTooltip } from "./emojiUtils";

const Leaderboard = ({ user, tournamentId, tournamentOver }) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [roundLeaderboard, setRoundLeaderboard] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState("Overall");
  const [orderedRounds, setOrderedRounds] = useState([]);
  const dropdownRef = useRef(null);
  const { renderPredefinedEmoji, renderCountryEmojis } = useEmojiTooltip();

  const fetchLeaderboard = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/leaderboard?tournamentId=${tournamentId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch leaderboard");
      }
      const data = await response.json();
      setLeaderboard(data);
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
    }
  }, [tournamentId]);

  const fetchRoundLeaderboard = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/round-leaderboard?tournamentId=${tournamentId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch round leaderboard");
      }
      const data = await response.json();

      if (typeof data !== "object" || data === null) {
        console.error("Received data is not an object:", data);
        return;
      }

      const roundsWithIds = Object.entries(data).map(([round, entries]) => {
        const id = entries[0]?.id || 0;
        return { round, id };
      });

      const sortedRounds = roundsWithIds
        .sort((a, b) => a.id - b.id)
        .map(({ round }) => round);

      setOrderedRounds(["Overall", ...sortedRounds]);
      setRoundLeaderboard(data);
    } catch (error) {
      console.error("Error fetching round leaderboard:", error);
    }
  }, [tournamentId]);

  useEffect(() => {
    const initializeData = async () => {
      await fetchLeaderboard();
      await fetchRoundLeaderboard();
    };

    initializeData();
  }, [fetchLeaderboard, fetchRoundLeaderboard]);

  const renderCellWithStar = (value, maxValue) => (
    <TableCell align="right">
      {value === maxValue &&
        value !== 0 &&
        renderPredefinedEmoji({ emojiKey: "star" })}
      {value}
    </TableCell>
  );

  const getMedalEmoji = (rank) => {
    if (!tournamentOver) return "";
    switch (rank) {
      case 1:
        return renderPredefinedEmoji({ emojiKey: "goldMedal" });
      case 2:
        return renderPredefinedEmoji({ emojiKey: "silverMedal" });
      case 3:
        return renderPredefinedEmoji({ emojiKey: "bronzeMedal" });
      default:
        return "";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderCroissantEmoji = (row) => {
    if (!tournamentOver || !row.croissant_count) return null;

    if ([2, 3, 4].includes(parseInt(tournamentId))) {
      if (row.croissant_count > 0) {
        const chocolateText = row.croissant_count === 1 ? "Chocolate" : "Chocolates";
        return renderPredefinedEmoji({
          emojiKey: "chocolate",
          customTooltip: `${row.croissant_count} Anonymous ${chocolateText} from ${row.croissant_count === 1 ? 'a Friend' : 'Friends'}`,
        });
      }
    } else if (row.is_croissant_leader) {
      return renderPredefinedEmoji({
        emojiKey: "croissant",
      });
    }

    return null;
  };

  const renderLeaderboardData = () => {
    const data =
      selectedRound === "Overall"
        ? leaderboard
        : roundLeaderboard[selectedRound] || [];

    const maxValues = data.reduce(
      (acc, row) => ({
        winner_points: Math.max(acc.winner_points, row.winner_points),
        handicap_points: Math.max(acc.handicap_points, row.handicap_points),
        top_scorer_points: Math.max(
          acc.top_scorer_points,
          row.top_scorer_points
        ),
      }),
      { winner_points: 0, handicap_points: 0, top_scorer_points: 0 }
    );

    return { data, maxValues };
  };

  const handleRoundSelection = (round) => {
    setSelectedRound(round);
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="rounds-dropdown" ref={dropdownRef}>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="overall-button"
        >
          {selectedRound} ▼
        </button>
        {isDropdownOpen && (
          <div className="round-dropdown">
            {orderedRounds.map((round) => (
              <button key={round} onClick={() => handleRoundSelection(round)}>
                {round}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table aria-label="Leaderboard">
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Total Points</TableCell>
                <TableCell align="right">Winner Points</TableCell>
                <TableCell align="right">Handicap Points</TableCell>
                <TableCell align="right">Top Scorer Points</TableCell>
                {selectedRound === "Overall" && (
                  <TableCell align="center">Expertise</TableCell>
                )}{" "}
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                const { data, maxValues } = renderLeaderboardData();
                return data.map((row) => (
                  <TableRow
                    className={`expandable-single-line-cell ${
                      user && row.user_id === user.id ? "highlighted-row" : ""
                    }`}
                    key={row.user_id}
                  >
                    <TableCell>
                      <b>{row.rank}.</b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {selectedRound === "Overall" &&
                          tournamentOver &&
                          getMedalEmoji(row.rank)}
                        <Link to={`/profile/${row.user_id}`}>{row.name}</Link>
                        {selectedRound === "Overall" && (
                          <>
                            {row.round_win_count > 0 &&
                              renderPredefinedEmoji({
                                emojiKey: "roundWinner",
                                count: row.round_win_count,
                                customTooltip: `${row.round_win_count} Round${
                                  row.round_win_count > 1 ? "s" : ""
                                } Won`,
                              })}
                            {row.on_fire &&
                              !tournamentOver &&
                              renderPredefinedEmoji({
                                emojiKey: "onFire",
                                customTooltip: `${row.name} is on fire!`,
                              })}
                            {renderCroissantEmoji(row)}
                          </>
                        )}
                      </b>
                    </TableCell>{" "}
                    <TableCell align="right">
                      <b>{row.total_points}</b>
                    </TableCell>
                    {renderCellWithStar(
                      row.winner_points,
                      maxValues.winner_points
                    )}
                    {renderCellWithStar(
                      row.handicap_points,
                      maxValues.handicap_points
                    )}
                    {renderCellWithStar(
                      row.top_scorer_points,
                      maxValues.top_scorer_points
                    )}{" "}
                    {selectedRound === "Overall" && (
                      <TableCell align="center">
                        <Tooltip arrow>
                          {renderCountryEmojis(row.expert_countries)}
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ));
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      </div>{" "}
      <style jsx>{`
        .table-container {
          padding: 20px 20px;
        }
        .rounds-dropdown {
          position: relative;
          padding: 12px 20px;
          border-bottom: 1px solid #e0e0e0;
          max-width: 700px;
          display: inline-flex;
        }
        .overall-button {
          background: none;
          border: none;
          background-color: #007bff;
          color: white;
          cursor: pointer;
          margin: 0 4px;
          white-space: nowrap;
          border-radius: 4px;
          flex-shrink: 0;
          font-family: "Titillium Web";
        }
        .round-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          font-family: "Titillium Web";
        }
        .round-dropdown button {
          display: block;
          width: 100%;
          text-align: left;
          padding: 8px 12px;
          border: none;
          background: none;
          cursor: pointer;
          font-family: "Titillium Web";
        }
        .highlighted-row {
          background-color: #f0f0f0;
        }
        .expandable-single-line-cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      `}</style>
    </div>
  );
};

export default Leaderboard;
