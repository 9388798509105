import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  formatGameTime,
  formatGameName,
  getCountdown,
  renderSpreadCell,
} from "./App";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import JokerIcon from "./JokerIcon";
import { Link } from "react-router-dom";
import { useEmojiTooltip } from "./emojiUtils";

function GameDetails({ user, tournamentId }) {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { renderPredefinedEmoji, renderApiEmojis } = useEmojiTooltip();

  const determineWinner = (game) => {
    if (!game.score1 || !game.score2) return null;
    const score1 = parseInt(game.score1);
    const score2 = parseInt(game.score2);
    if (score1 > score2) return game.team1;
    if (score2 > score1) return game.team2;
    return "Draw";
  };

  const determineSpreadWinner = (game) => {
    if (!game.score1 || !game.score2) return null;
    const score1 = parseInt(game.score1) + parseFloat(game.spread);
    const score2 = parseInt(game.score2);
    if (score1 > score2) return game.team1;
    if (score2 > score1) return game.team2;
    return "Draw";
  };

  useEffect(() => {
    async function fetchGameAndPredictions() {
      try {
        const [gameResponse, predictionsResponse] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_API_URL}/api/games/${id}?tournamentId=${tournamentId}`
          ),
          fetch(
            `${process.env.REACT_APP_API_URL}/api/games/${id}/predictions?tournamentId=${tournamentId}`
          ),
        ]);

        if (!gameResponse.ok || !predictionsResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const [gameData, predictionsData] = await Promise.all([
          gameResponse.json(),
          predictionsResponse.json(),
        ]);

        setGame(gameData);
        setPredictions(predictionsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchGameAndPredictions();
  }, [id, tournamentId]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!game) return <div>Game not found</div>;

  const aggregatePredictions = (key) => {
    return predictions.reduce((acc, pred) => {
      const value = pred[key];
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  };

  const renderWinnerPredictionTable = (data) => {
    const entries = Object.entries(data);
    const total = entries.reduce((sum, [_, count]) => sum + count, 0);
    const winner = determineWinner(game);
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Winner</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(([prediction, count]) => (
              <TableRow key={prediction}>
                <TableCell>
                  {prediction} {winner === prediction && renderPredefinedEmoji({emojiKey: "correct"})}{" "}
                </TableCell>
                <TableCell align="right">
                  {count} ({((count / total) * 100).toFixed(1)}%)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderHandicapPredictionTable = (data) => {
    const entries = Object.entries(data);
    const total = entries.reduce((sum, [_, count]) => sum + count, 0);
    const winner = determineSpreadWinner(game);

    const formatHandicap = (prediction) => {
      const spread = parseFloat(game.spread);
      if (prediction === game.team1) {
        return `${prediction} (${spread >= 0 ? '+' : ''}${spread})`;
      } else if (prediction === game.team2) {
        return `${prediction} (${spread <= 0 ? '+' : '-'}${Math.abs(spread)})`;
      }
      return prediction;
    };

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Handicap</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(([prediction, count]) => (
              <TableRow key={prediction}>
                <TableCell>
                  {formatHandicap(prediction)} {winner === prediction && renderPredefinedEmoji({emojiKey: "correct"})}{" "}
                </TableCell>
                <TableCell align="right">
                  {count} ({((count / total) * 100).toFixed(1)}%)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>    );
  };

  const renderTopScorerPredictionTable = (data) => {
    const entries = Object.entries(data);
    const total = entries.reduce((sum, [_, count]) => sum + count, 0);
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Winner</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(([prediction, count]) => {

              const hasPartialMatch =
                game.top_scorer && game.top_scorer.includes(prediction);

              return (
                <TableRow key={prediction}>
                  <TableCell>
                    {prediction} {hasPartialMatch && renderPredefinedEmoji({emojiKey: "correct"})}{" "}
                  </TableCell>

                  <TableCell align="right">
                    {count} ({((count / total) * 100).toFixed(1)}%)
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>{" "}
        </Table>
      </TableContainer>
    );
  };
  const winnerPredictions = aggregatePredictions("straight_prediction");
  const handicapPredictions = aggregatePredictions("spread_prediction");
  const topScorerPredictions = aggregatePredictions("top_scorer_prediction");

  return (
    <>
      <div className="GameDetails">
        <h2>{formatGameName(game)}</h2>
        <p>
          {formatGameTime(game.date)}
          <br />
          {game.score1 ? "Final result" : getCountdown(game.date)}
        </p>
        {game.top_scorer && <p>Top Scorer: {game.top_scorer}</p>}
      </div>
      <h2>Predictions</h2>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="expandable-single-line-cell">
                <TableCell>User</TableCell>
                <TableCell></TableCell>
                <TableCell>Total Points</TableCell>
                <TableCell colSpan={2}>Winner</TableCell>
                <TableCell colSpan={2}>Handicap</TableCell>
                <TableCell colSpan={2}>Top Scorer</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow className="expandable-single-line-cell">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {predictions.map((prediction) => (
                <TableRow
                  className={`expandable-single-line-cell ${
                    user && prediction.user_id === user.id
                      ? "highlighted-row"
                      : ""
                  }`}
                  key={prediction.id || prediction.user_name}
                >
                  <TableCell>
                    <b>
                      <Link to={`/profile/${prediction.user_id}`}>
                        {prediction.user_name}
                      </Link>
                    </b>
                  </TableCell>
                  <TableCell>
                    {prediction.is_double_points && (
                      <JokerIcon isActive={true} isButton={false} />
                    )}
                  </TableCell>
                  <TableCell>
                    <b>
                      {prediction.total_points !== undefined
                        ? prediction.total_points
                        : ""}
                    </b>
                  </TableCell>
                  <TableCell>{prediction.straight_prediction || ""}</TableCell>
                  <TableCell>
                    {prediction.straight_points !== undefined
                      ? prediction.straight_points
                      : ""}
                  </TableCell>
                  <TableCell>
                    {prediction.spread_prediction
                      ? renderSpreadCell(game, prediction)
                      : ""}
                  </TableCell>
                  <TableCell>
                    {prediction.spread_points !== undefined
                      ? prediction.spread_points
                      : ""}
                  </TableCell>
                  <TableCell>
                    {prediction.top_scorer_prediction || ""}
                  </TableCell>
                  <TableCell>
                    {prediction.top_scorer_points !== undefined
                      ? prediction.top_scorer_points
                      : ""}
                  </TableCell>
                  <TableCell>
                    {prediction.emoji !== undefined ? renderApiEmojis(prediction.emoji) : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <h2>Prediction Statistics</h2>
      <div className="prediction-tables-container">
        <div className="prediction-tables-left-column">
          <div className="prediction-table">
            {renderWinnerPredictionTable(winnerPredictions)}
          </div>
          <div className="prediction-table">
            {renderHandicapPredictionTable(handicapPredictions)}
          </div>
        </div>
        <div className="prediction-tables-right-column">
          <div className="prediction-table">
            {renderTopScorerPredictionTable(topScorerPredictions)}
          </div>
        </div>
      </div>
    </>
  );
}

export default GameDetails;
