import React, { useState, useEffect, useCallback, useRef } from "react";
import JokerIcon from "./JokerIcon";
import { formatGameTime, formatGameName, getUTC2Date } from "./App";

const RoundsCarousel = ({ rounds, currentRound, setCurrentRound }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const activeButton = carouselRef.current.querySelector(".active");
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }
    }
  }, [currentRound]);

  const nextRound = () => {
    const currentIndex = rounds.indexOf(currentRound);
    if (currentIndex < rounds.length - 1) {
      setCurrentRound(rounds[currentIndex + 1]);
    }
  };

  const prevRound = () => {
    const currentIndex = rounds.indexOf(currentRound);
    if (currentIndex > 0) {
      setCurrentRound(rounds[currentIndex - 1]);
    }
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px'
    }}>
      <button onClick={prevRound} style={{
        fontSize: '1em',
        background: 'none',
        border: 'none',
        color: rounds.indexOf(currentRound) === 0 ? '#cccccc' : '#333333',
        cursor: rounds.indexOf(currentRound) === 0 ? 'default' : 'pointer',
        padding: '5px 10px',
        flexShrink: 0
      }} disabled={rounds.indexOf(currentRound) === 0}>
        &lt;
      </button>
      <div ref={carouselRef} style={{
        display: 'flex',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        maxWidth: '700px',
        padding: '0 5px'
      }}>
        {rounds.map((round) => (
          <button
            key={round}
            onClick={() => setCurrentRound(round)}
            className={round === currentRound ? "active" : ""}
            style={{
              background: round === currentRound ? '#007bff' : 'none',
              border: 'none',
              color: round === currentRound ? 'white' : '#333333',
              cursor: 'pointer',
              margin: '0 4px',
              whiteSpace: 'nowrap',
              borderRadius: '4px',
              flexShrink: 0,
              fontFamily: '"Titillium Web", sans-serif',
              padding: '8px 12px',
              scrollSnapAlign: 'center'
            }}
          >
            {round}
          </button>
        ))}
      </div>
      <button onClick={nextRound} style={{
        fontSize: '1em',
        background: 'none',
        border: 'none',
        color: rounds.indexOf(currentRound) === rounds.length - 1 ? '#cccccc' : '#333333',
        cursor: rounds.indexOf(currentRound) === rounds.length - 1 ? 'default' : 'pointer',
        padding: '5px 10px',
        flexShrink: 0
      }} disabled={rounds.indexOf(currentRound) === rounds.length - 1}>
        &gt;
      </button>
    </div>
  );
};

function Predictions({
  user,
  games,
  predictions,
  setPredictions,
  rounds,
  currentRound,
  setCurrentRound,
  countdowns,
  tournamentId,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const [openDropdowns, setOpenDropdowns] = useState({});
  const [shuffledPlayers, setShuffledPlayers] = useState({});

  const dropdownRef = useRef({});

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  };

  useEffect(() => {
    const newShuffledPlayers = {};
    games.forEach((game) => {
      newShuffledPlayers[game.id] = shuffleArray([...game.players]);
    });
    setShuffledPlayers(newShuffledPlayers);
  }, [games]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(openDropdowns).forEach((gameId) => {
        if (
          dropdownRef.current[gameId] &&
          !dropdownRef.current[gameId].contains(event.target)
        ) {
          setOpenDropdowns((prev) => ({ ...prev, [gameId]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdowns]);

  const toggleDropdown = (gameId) => {
    setOpenDropdowns((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const handleError = useCallback((message) => {
    console.error(message);

    setError(message);
  }, []);

  const fetchPredictions = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${user.id}/old?tournamentId=${tournamentId}`
      );

      if (!response.ok) throw new Error("Failed to fetch predictions");

      const userData = await response.json();

      const formattedPredictions = {};

      userData.predictions.forEach((pred) => {
        formattedPredictions[pred.game_id] = {
          straight: pred.straight_prediction,

          spread: pred.spread_prediction,

          topScorer: pred.top_scorer_prediction,

          isDoublePoints: pred.is_double_points,
        };
      });

      setPredictions(formattedPredictions);
    } catch (error) {
      console.error("Error in fetchPredictions:", error);

      handleError("Failed to fetch predictions");
    } finally {
      setIsLoading(false);
    }
  }, [user, setPredictions, tournamentId, handleError]);

  useEffect(() => {
    if (user && user.id) {
      fetchPredictions();
    }
  }, [user, fetchPredictions]);

  const handlePrediction = async (game_id, prediction, againstSpread) => {
    if (!user) {
      handleError("You must be logged in to make predictions.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) throw new Error("Invalid game selected");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            straight_prediction: againstSpread ? null : prediction,

            spread_prediction: againstSpread ? prediction : null,

            is_double_points: predictions[game_id]?.isDoublePoints || false,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to save prediction");
      }

      await fetchPredictions();
    } catch (err) {
      handleError(`Error saving prediction: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopScorer = async (game_id, player) => {
    if (!user) {
      handleError("You must be logged in to make predictions.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) throw new Error("Invalid game selected");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            top_scorer_prediction: player,

            is_double_points: predictions[game_id]?.isDoublePoints || false,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to save top scorer prediction");

      await fetchPredictions();
    } catch (err) {
      handleError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDoublePoints = async (game_id) => {
    if (!user) {
      handleError("You must be logged in to set double points.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) {
        throw new Error("Game not found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions/double-points`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            round: game.round,

            is_double_points: !predictions[game_id]?.isDoublePoints,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to set double points");
      }

      await fetchPredictions();
    } catch (err) {
      handleError(`Error in handleDoublePoints: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // const renderRoundLinks = () => (
  //   <div className="round-links">
  //     {rounds.map((round) => (
  //       <button
  //         key={round}
  //         onClick={() => setCurrentRound(round)}
  //         className={currentRound === round ? "active" : ""}
  //       >
  //         {round}
  //       </button>
  //     ))}
  //   </div>
  // );

  const isTopScorerCorrect = (prediction, actual) => {
    if (!prediction || !actual) return false;

    const actualScorers = actual.split(",").map((s) => s.trim());

    return actualScorers.includes(prediction);
  };

  const formatSpread = (team, spread) => {
    if (team === "team1") {
      return spread > 0 ? `(+${spread})` : `(${spread})`;
    } else {
      return spread > 0 ? `(${-spread})` : `(+${-spread})`;
    }
  };

  const isDoublePointsDisabled = useCallback(
    (gameId) => {
      const currentTime = getUTC2Date();

      const game = games.find((g) => g.id === gameId);

      if (!game) {
        console.error(`Game with id ${gameId} not found`);

        return true;
      }

      const gameTime = new Date(game.date);

      if (currentTime >= gameTime) {
        return true;
      }

      const doublePointsGameForRound = Object.entries(predictions).find(
        ([id, pred]) => {
          const predGame = games.find((g) => g.id === parseInt(id));

          return pred.isDoublePoints && predGame?.round === game.round;
        }
      );

      if (doublePointsGameForRound) {
        const [doublePointsGameId] = doublePointsGameForRound;

        const doublePointsGame = games.find(
          (g) => g.id === parseInt(doublePointsGameId)
        );

        if (doublePointsGame) {
          const doublePointsGameTime = new Date(doublePointsGame.date);

          if (currentTime >= doublePointsGameTime) {
            console.log(
              `Double points game ${doublePointsGameId} has started, disabling for all games in round ${game.round}`
            );

            return true;
          }
        }
      }

      return false;
    },
    [games, predictions]
  );

  const renderGames = () => {
    return (
      <div className="games-container">
        {games
          .filter((game) => game.round === currentRound)
          .map((game) => {
            const gameTime = new Date(game.date);
            const isGameStarted = getUTC2Date() >= gameTime;
            const isDoublePoints = predictions[game.id]?.isDoublePoints;

            return (
              <div
                key={game.id}
                className={`game-card ${
                  isDoublePoints ? "double-points-game" : "single-game"
                }`}
              >
                <div className="game-card-head">
                  <p>{formatGameName(game)}</p>
                  {formatGameTime(game.date)}
                  <br />
                  {game.score1 ? "Final result" : countdowns[game.id]}
                </div>
                {/* Straight prediction */}
                <div style={{ marginTop: "10px" }}>
                  <button
                    disabled={isGameStarted}
                    onClick={() => handlePrediction(game.id, game.team1, false)}
                    className={`
                      ${
                        predictions[game.id]?.straight === game.team1
                          ? "button-selected"
                          : ""
                      }
                      ${
                        game.score1 > game.score2 &&
                        predictions[game.id]?.straight === game.team1
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.score1 < game.score2 &&
                        predictions[game.id]?.straight === game.team1
                          ? "incorrect-prediction"
                          : ""
                      }
                    `}
                  >
                    {game.team1}
                  </button>
                  <button
                    disabled={isGameStarted}
                    onClick={() => handlePrediction(game.id, game.team2, false)}
                    className={`
                      ${
                        predictions[game.id]?.straight === game.team2
                          ? "button-selected"
                          : ""
                      }
                      ${
                        game.score1 < game.score2 &&
                        predictions[game.id]?.straight === game.team2
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.score1 > game.score2 &&
                        predictions[game.id]?.straight === game.team2
                          ? "incorrect-prediction"
                          : ""
                      }
                    `}
                  >
                    {game.team2}
                  </button>
                </div>
                {/* Spread prediction */}
                {game.spread != null ? (
                  <div style={{ marginTop: "10px" }}>
                    {(() => {
                      const team1Predicted =
                        predictions[game.id]?.spread === game.team1;
                      const team2Predicted =
                        predictions[game.id]?.spread === game.team2;
                      const gameFinished =
                        game.score1 != null && game.score2 != null;
                      const spread = parseFloat(game.spread);
                      const team1CoveredSpread =
                        gameFinished && game.score1 + spread > game.score2;
                      const team2CoveredSpread =
                        gameFinished && game.score1 + spread < game.score2;
                      const getButtonClass = (
                        teamPredicted,
                        teamCoveredSpread
                      ) => {
                        if (!gameFinished)
                          return teamPredicted ? "button-selected" : "";
                        if (teamPredicted && teamCoveredSpread)
                          return "button-selected correct-prediction";
                        if (teamPredicted && !teamCoveredSpread)
                          return "button-selected incorrect-prediction";
                        return "";
                      };

                      const team1Class = getButtonClass(
                        team1Predicted,
                        team1CoveredSpread,
                        game.id,
                        game.team1
                      );
                      const team2Class = getButtonClass(
                        team2Predicted,
                        team2CoveredSpread,
                        game.id,
                        game.team2
                      );

                      return (
                        <>
                          <button
                            disabled={isGameStarted}
                            onClick={() =>
                              handlePrediction(game.id, game.team1, true)
                            }
                            className={team1Class}
                          >
                            {game.team1} {formatSpread("team1", game.spread)}
                          </button>
                          <button
                            disabled={isGameStarted}
                            onClick={() =>
                              handlePrediction(game.id, game.team2, true)
                            }
                            className={team2Class}
                          >
                            {game.team2} {formatSpread("team2", game.spread)}
                          </button>
                        </>
                      );
                    })()}
                  </div>
                ) : (
                  <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                    Spread not yet available
                  </div>
                )}
                {/* Top scorer prediction */}
                <div
                  className="top-scorer-container"
                  ref={(el) => (dropdownRef.current[game.id] = el)}
                >
                  {" "}
                  Top Scorer:{" "}
                  <button
                    onClick={() => toggleDropdown(game.id)}
                    disabled={isGameStarted}
                    className={`
                      top-scorer-button
                      ${
                        predictions[game.id]?.topScorer ? "button-selected" : ""
                      }
                      ${
                        game.top_scorer &&
                        isTopScorerCorrect(
                          predictions[game.id]?.topScorer,
                          game.top_scorer
                        )
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.top_scorer &&
                        predictions[game.id]?.topScorer &&
                        !isTopScorerCorrect(
                          predictions[game.id]?.topScorer,
                          game.top_scorer
                        )
                          ? "incorrect-prediction"
                          : ""
                      }
                      ${isGameStarted ? "disabled" : ""}
                    `}
                  >
                    {predictions[game.id]?.topScorer || "Select player"}
                  </button>
                  {openDropdowns[game.id] && (
                    <div className="top-scorer-dropdown">
                      <button
                        onClick={() => {
                          handleTopScorer(game.id, "");
                          toggleDropdown(game.id);
                        }}
                      >
                        Select player
                      </button>
                      {shuffledPlayers[game.id] &&
                        shuffledPlayers[game.id].map((player) => (
                          <button
                            key={player}
                            onClick={() => {
                              handleTopScorer(game.id, player);
                              toggleDropdown(game.id);
                            }}
                          >
                            {player}
                          </button>
                        ))}
                    </div>
                  )}
                </div>{" "}
                {/* Double points selection */}
                <div style={{ marginTop: "10px" }}>
                  <JokerIcon
                    isActive={isDoublePoints}
                    isButton
                    isDisabled={isDoublePointsDisabled(game.id)}
                    onClick={() => handleDoublePoints(game.id)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="predictions-container">
      <style jsx>{`
        .games-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          max-width: 1200px; /* Adjust as needed */
          margin: 0 auto;
          padding: 20px 20px;
        }
        .game-card {
          width: 100%;
          // max-width: 500px; /* Adjust based on your desired maximum game width */
          margin: 0;
        }
        .game-card:nth-child(odd) {
          justify-self: end;
        }
        .game-card:nth-child(even) {
          justify-self: start;
        }
        @media (max-width: 767px) {
          .games-container {
            grid-template-columns: 1fr;
          }
          .game-card,
          .game-card:nth-child(odd),
          .game-card:nth-child(even) {
            justify-self: center;
          }
        }
        .rounds-carousel {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0px;
        }

        .rounds-carousel__container {
          display: flex;
          overflow-x: auto;
          scroll-behavior: smooth;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
          -ms-overflow-style: none;
          white-space: nowrap;
          max-width: 700px;
          padding: 0;
          margin: 0;
          border: none;
        }

        .rounds-carousel__container::-webkit-scrollbar {
          display: none;
        }

        .rounds-carousel__content {
          display: inline-flex;
        }

        .rounds-carousel__button {
          background: none;
          border: none;
          color: #333333;
          cursor: pointer;
          margin: 0 4px;
          white-space: nowrap;
          border-radius: 4px;
          flex-shrink: 0;
          font-family: "Titillium Web";
          padding: 8px 12px;
        }

        .rounds-carousel__button.active {
          background-color: #007bff;
          color: white;
        }

        .carousel-nav {
          font-size: 1em;
          background: none;
          border: none;
          color: #333333;
          cursor: pointer;
          padding: 5px 10px;
          flex-shrink: 0;
        }

        .carousel-nav:disabled {
          color: #cccccc;
          cursor: initial;
        }

        @media (max-width: 768px) {
          .rounds-carousel__container {
            max-width: 200px;
          }
        }
        .top-scorer-dropdown {
          position: absolute;
          left: 0;
          right: 0;
          text-align: right !important;
          background-color: white;
          border: 1px solid #ced4da;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          max-height: 450px; /* Increased from 200px to show more names */
          overflow-y: auto;
          overflow-x: hidden;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .top-scorer-dropdown::-webkit-scrollbar {
          display: none;
        }
        .top-scorer-dropdown button {
          display: block;
          width: 100%;
          text-align: left;
          padding: 8px 12px;
          border: none;
          background: none;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .top-scorer-container {
          position: relative;
          display: block;
          width: 100%;
          margin-top: 10px;
        }

        // And let's adjust the .top-scorer-button to match:

        .top-scorer-button {
          width: 100%;
          text-align: left;
          padding: 8px 12px;
          background-color: #f8f9fa;
          border: 1px solid #ced4da;
          border-radius: 4px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
      <RoundsCarousel
        rounds={rounds}
        currentRound={currentRound}
        setCurrentRound={setCurrentRound}
      />
      {currentRound && renderGames()}
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

export default Predictions;
