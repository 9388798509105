import React, { useState, useEffect, useContext, useRef  } from "react";
import io from "socket.io-client";
import { AuthContext } from "./contexts/AuthContext";
import { Link } from "react-router-dom";
import { format, isToday, isYesterday, isThisWeek, isThisYear } from "date-fns";

const socket = io("https://www.playmaker.ee", {
  path: "/socket.io",
  transports: ["websocket", "polling"],
  secure: true,
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

const formatMessageDate = (timestamp) => {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return format(date, "HH:mm");
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, "HH:mm")}`;
  } else if (isThisWeek(date)) {
    return format(date, "EEEE, HH:mm");
  } else if (isThisYear(date)) {
    return format(date, "dd MMM, HH:mm");
  } else {
    return format(date, "dd MMM yy, HH:mm");
  }
};

const validTLDs = [
  "com",
  "net",
  "org",
  "edu",
  "gov",
  "io",
  "ai",
  "app",
  "dev",
  "ee",
  "basketball",
  "watch",
  "lv",
  "lt",
];

const enhancedLinkify = (text) => {
  const tldPattern = validTLDs.join("|");
  const urlRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    `(https?:\/\/)?([\\w-]+\\.)+(?:${tldPattern})(\\/\\S*)?`,
    "gi"
  );

  let lastIndex = 0;
  const parts = [];
  let match;

  while ((match = urlRegex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }

    let href = match[0];
    if (!href.startsWith("http")) {
      href = "https://" + href;
    }

    parts.push(
      <a
        key={match.index}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        {match[0]}
      </a>
    );

    lastIndex = urlRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const Chat = ({tournamentId}) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const { user } = useContext(AuthContext);
  const messageListRef = useRef(null);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const notificationRef = useRef(null);
  const pendingMessagesRef = useRef([]);

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      console.log("Notification permission after request:", permission);
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  const showGroupedNotification = () => {
    console.log("showGroupedNotification called");
    if (notificationRef.current) {
      notificationRef.current.close();
    }

    const messageCount = pendingMessagesRef.current.length;
    const lastMessage = pendingMessagesRef.current[messageCount - 1];

    console.log("Creating new notification");
    notificationRef.current = new Notification("New Messages", {
      body:
        messageCount === 1
          ? `${lastMessage.user_name}: ${lastMessage.content}`
          : `${messageCount} new messages. Last: ${lastMessage.user_name}: ${lastMessage.content}`,
      icon: "/logo192.png",
    });

    notificationRef.current.onclick = () => {
      window.focus();
      notificationRef.current.close();
    };

    pendingMessagesRef.current = [];
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/messages?tournamentId=${tournamentId}`)
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => console.error("Error fetching messages:", error));

    requestNotificationPermission();

    console.log("Initial notification permission:", Notification.permission);

    const handleVisibilityChange = () => {
      const newVisibility = !document.hidden;
      console.log("Page visibility changed:", newVisibility);
      setIsPageVisible(newVisibility);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    socket.on("chat message", (msg) => {
      console.log("New message received:", msg);
      console.log("Page visibility:", isPageVisible);
      console.log("Notification permission:", Notification.permission);

      if (msg.id && msg.user_name && msg.timestamp) {
        setMessages((prevMessages) => [...prevMessages, msg]);

        if (Notification.permission === "granted" && !isPageVisible) {
          console.log("Attempting to show notification");
          pendingMessagesRef.current.push(msg);

          if (pendingMessagesRef.current.length === 1) {
            setTimeout(showGroupedNotification, 1000);
          }
        }
      }
    });

    return () => {
      socket.off("chat message");
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (notificationRef.current) {
        notificationRef.current.close();
      }
    };
  }, [isPageVisible, tournamentId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim() && user) {
      socket.emit("chat message", { user_id: user.id, content: inputMessage });
      setInputMessage("");
    }
  };

  return (
    <div className="chat-container">
      <div ref={messageListRef} className="message-list">
        {messages.map((msg, index) => (
          <div key={index} className="message" style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold" }}>
              <Link to={`/profile/${msg.user_id}`}>{msg.user_name}</Link>
            </span>
            <span
              style={{ color: "#888", marginLeft: "5px", fontSize: "0.8em" }}
            >
              ({formatMessageDate(msg.timestamp)})
            </span>
            :
            <span style={{ marginLeft: "5px" }}>
              {enhancedLinkify(msg.content)}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={sendMessage} className="message-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          className="message-input"
        />
        <button type="submit" className="send-button">
          Send
        </button>
      </form>
      <button
        onClick={() => {
          const testMsg = { user_name: "Test User", content: "Test Message" };
          pendingMessagesRef.current.push(testMsg);
          showGroupedNotification();
        }}
      >
        Test Notification
      </button>
    </div>
  );
};

export default Chat;
