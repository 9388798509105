import React, { createContext, useState, useEffect, useCallback } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const addUserToDatabase = async (userData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userData.name,
          email: userData.email,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add user to database');
      }

      const dbUser = await response.json();
      return dbUser;
    } catch (error) {
      console.error('Error adding user to database:', error);
      throw error;
    }
  };

  const updateUser = useCallback((updatedUser) => {
    setUser(updatedUser);
    localStorage.setItem('userData', JSON.stringify(updatedUser));
  }, []);
  
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const res = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`);
        if (!res.ok) throw new Error('Failed to fetch user data');
        const userData = await res.json();
        
        // Add user to database
        const dbUser = await addUserToDatabase(userData);
        
        setUser(dbUser);
        localStorage.setItem('userData', JSON.stringify(dbUser));
      } catch (err) {
        console.error('Error during login:', err);
        // Implement user-facing error handling here
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setLoading(false);
      // Implement user-facing error handling here
    }
  });

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('userData');
    // Implement Google logout if necessary
  }, []);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser: updateUser, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProviderWithGoogle = ({ children }) => (
  <GoogleOAuthProvider clientId="70835742487-5svvqs5ok8vnmbvi86pua2tgprl2g37l.apps.googleusercontent.com">
    <AuthProvider>{children}</AuthProvider>
  </GoogleOAuthProvider>
);