import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        Questions? Comments? Suggestions? <p><a href="mailto:mart@disroot.org">mart@disroot.org</a></p>
        <p>&copy; {currentYear} playmaker.ee. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;