import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AuthContext } from "./contexts/AuthContext";
import {
  formatGameTime,
  formatGameName,
  getUTC2Date,
  renderSpreadCell,
} from "./App";
import JokerIcon from "./JokerIcon";
import { useParams, Navigate } from "react-router-dom";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { emojiBlast } from "emoji-blast";
import { useEmojiTooltip } from "./emojiUtils";

const GiantEmoji = styled.span`
  font-size: 1em; // This makes it the same size as the parent text
  line-height: 1; // This ensures the emoji doesn't affect the line height
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: inline-block;
  vertical-align: middle; // This aligns it vertically with the text
  margin-left: 0.2em; // Add a small space between the text and the emoji
  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const UserProfile = ({ tournamentId }) => {
  const { userId } = useParams();
  const { user, setUser, logout } = useContext(AuthContext);
  const [profileData, setProfileData] = useState({ user: {}, predictions: [] });
  const [groupedGames, setGroupedGames] = useState({});
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState("");
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [croissantCount, setCroissantCount] = useState(0);
  const [historicalData, setHistoricalData] = useState([]);
  const navigate = useNavigate();
  const { renderPredefinedEmoji, renderCountryEmojis, renderApiEmojis } =
    useEmojiTooltip();

  const greetings = [
    "Ahoi",
    "Sveiki",
    "Hej",
    "Aloha",
    "こんにちは",
    "Hola",
    "Ciao",
    "Salut",
    "Hallo",
    "안녕하세요",
    "Olá",
    "Merhaba",
    "Привет",
    "Γειά σου",
    "Hallo",
    "Shalom",
    "नमस्ते",
    "Sawubona",
    "Hei",
    "Konnichiwa",
    "Sveiki",
    "Tere",
    "Mabuhay",
    "Zdravo",
    "Czesc",
    "Szia",
    "Xin chào",
    "Dia dhuit",
    "Selamat pagi",
    "Salam",
    "Yassas",
    "Habari",
    "Kamusta",
    "Ahoj",
    "Jó napot",
    "Bok",
    "Здравствуйте",
    "Salve",
    "Halo",
    "Bonjour",
    "Kia ora",
    "Sawadee",
    "Ahoy",
    "Marhaba",
    "Geia",
    "Olá",
    "God dag",
    "Halo",
    "Namaskar",
    "Ayubowan",
    "Merhaba",
    "Dzień dobry",
    "Χαῖρε", // Ancient Greek
    "χαίρετε", // Ancient Greek (plural/formal)
    "Shalom", // Ancient Hebrew
    "Salvete", // Latin (plural)
    "Salus", // Latin (alternative)
    "Jóreḥ", // Sumerian
    "ḥiā", // Old Egyptian
    "As-salamu alaykum", // Classical Arabic
    "Saluete", // Latin (alternative spelling)
    "Salvēte", // Latin (alternative spelling)
    "Anabada", // Akkadian
    "Khaire", // Ancient Greek (alternative)
    "Pax vobiscum", // Latin (peace be with you, often used as a greeting)
    "Bula", // Fijian (used historically)
    "Haere mai", // Maori (used historically)
    "Ahalan", // Aramaic
    "நமஸ்காரம்", // Hindi
    "स्वागतम्", // Sanskrit
    "مرحبًا", // Arabic (alternative)
    "你好", // Chinese (simplified)
    "สวัสดีครับ", // Thai (male speaker)
    "ሰላም", // Amharic
    "שלום", // Hebrew (modern)
    "ሀሌሎ", // Ge'ez
    "እንደምን አላችሁ", // Amharic (alternative),
    "Howdy",
    "Yo",
    "G'day",
    "'ello",
    "Hey",
    "Hey hey",
  ];

  const greeting = useMemo(() => {
    return greetings[Math.floor(Math.random() * greetings.length)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && userId) {
      setIsCurrentUser(user.id === parseInt(userId));
      fetchUserProfile(userId, tournamentId);
      fetchHistoricalData(userId);
      if (user.id === parseInt(userId)) {
        fetchCroissantCount(userId, tournamentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userId, tournamentId]);

  const fetchHistoricalData = async (profileId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/leaderboard/user/${profileId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch historical data");
      }
      const data = await response.json();
      setHistoricalData(data);
    } catch (error) {
      console.error("Error fetching historical data:", error);
      setError("Failed to load historical data. Please try again later.");
    }
  };

  const getCurrentTournamentStatus = useMemo(() => {
    const currentTournament = historicalData.find(
      (t) => t.id === parseInt(tournamentId)
    );
    if (!currentTournament) {
      return null;
    }
    return currentTournament.is_over;
  }, [historicalData, tournamentId]);

  const fetchCroissantCount = async (profileId, tournamentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/croissant/count/${profileId}?tournamentId=${tournamentId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch croissant count");
      }
      const data = await response.json();
      setCroissantCount(data.count);
    } catch (error) {
      console.error("Error fetching croissant count:", error);
    }
  };

  const fetchUserProfile = async (profileId, tournamentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${profileId}?tournamentId=${tournamentId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
      const data = await response.json();
      setProfileData(data);
      groupPredictionsByRound(data.predictions);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setError("Failed to load user profile. Please try again later.");
    }
  };

  const groupPredictionsByRound = (predictions) => {
    const grouped = predictions.reduce((acc, prediction) => {
      if (!acc[prediction.round]) {
        acc[prediction.round] = [];
      }
      acc[prediction.round].push(prediction);
      return acc;
    }, {});
    setGroupedGames(grouped);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleNameEdit();
    }
  };

  const handleGiveCroissant = async () => {
    if (
      isCurrentUser ||
      getCurrentTournamentStatus === null ||
      getCurrentTournamentStatus
    ) {
      console.log(
        "Cannot give croissants: wrong user, unknown tournament status, or tournament is over"
      );
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/croissant/give/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tournamentId }),
        }
      );
      if (!response.ok) throw new Error("Failed to give croissant");

      emojiBlast({
        emojis: [
          "🥐",
          "🥐",
          "🥐",
          "🥐",
          "🥐",
          "🥐",
          "🥐",
          "🥐",
          "🇫🇷",
          "🇫🇷",
          "🇫🇷",
          "🇫🇷",
          "🇫🇷",
          "🇫🇷",
          "🇫🇷",
          "🗼",
          "🗼",
          "🗼",
          "🥖",
          "🥖",
          "🥖",
          "🧀",
          "🍷",
          "🍾",
          "👨‍🍳",
          "⚜️",
          "🏰",
          "🐸",
          "🚬",
        ],
      });
    } catch (error) {
      console.error("Error giving croissant:", error);
    }
  };

  const getMedalEmoji = (rank) => {
    switch (rank) {
      case 1:
        return renderPredefinedEmoji({ emojiKey: "goldMedal" });
      case 2:
        return renderPredefinedEmoji({ emojiKey: "silverMedal" });
      case 3:
        return renderPredefinedEmoji({ emojiKey: "bronzeMedal" });
      default:
        return "";
    }
  };

  const removeEmojis = (text) => {
    return text.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi,
      ""
    );
  };
  const handleNameEdit = async () => {
    if (!isEditing) {
      setIsEditing(true);
      setNewName(profileData.user.name);
      setNameError("");
      return;
    }

    const trimmedName = newName.trim();
    const nameWithoutEmojis = removeEmojis(trimmedName);

    if (nameWithoutEmojis.length < 2 || nameWithoutEmojis.length > 32) {
      setNameError("Name must be between 2 and 32 characters long.");
      return;
    }

    if (nameWithoutEmojis !== trimmedName) {
      setNameError("Emojis are not allowed in the name.");
      return;
    }

    if (trimmedName.length < 2 || trimmedName.length > 32) {
      setNameError("Name must be between 2 and 32 characters long.");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/user/${user.id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: trimmedName }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorData}`
        );
      }

      const updatedUser = await response.json();
      setProfileData((prevData) => ({
        ...prevData,
        user: updatedUser,
      }));

      setUser(updatedUser);
      setIsEditing(false);
      setNameError("");
    } catch (e) {
      console.error("Error updating user name:", e);
      setNameError(`Failed to update name: ${e.message}`);
    }
  };

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!userId) {
    return <Navigate to={`/profile/${user.id}`} />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const renderPredictionRow = (prediction) => {
    const gameTime = new Date(prediction.date);
    const gameStarted = getUTC2Date() >= gameTime;
    const showPrediction = isCurrentUser || gameStarted;
    return (
      <TableRow
        className="expandable-single-line-cell"
        key={prediction.game_id}
      >
        <TableCell>{formatGameTime(prediction.date)}</TableCell>
        <TableCell>{formatGameName(prediction)}</TableCell>
        <TableCell>
          {(gameStarted || isCurrentUser) && prediction.is_double_points && (
            <JokerIcon isActive={true} isButton={false} />
          )}
        </TableCell>
        <TableCell>
          <b>
            {showPrediction && prediction.total_points != null
              ? prediction.total_points
              : ""}
          </b>
        </TableCell>
        <TableCell>
          {showPrediction ? prediction.straight_prediction || "" : ""}
        </TableCell>
        <TableCell>
          {showPrediction && prediction.straight_points != null
            ? prediction.straight_points
            : ""}
        </TableCell>
        <TableCell>
          {showPrediction ? renderSpreadCell(prediction, prediction) : ""}
        </TableCell>
        <TableCell>
          {showPrediction && prediction.spread_points != null
            ? prediction.spread_points
            : ""}
        </TableCell>
        <TableCell>
          {showPrediction ? prediction.top_scorer_prediction || "" : ""}
        </TableCell>
        <TableCell>
          {showPrediction && prediction.top_scorer_points != null
            ? prediction.top_scorer_points
            : ""}
        </TableCell>
        <TableCell>
          {showPrediction && prediction.emoji != null
            ? renderApiEmojis(prediction.emoji)
            : ""}
        </TableCell>
      </TableRow>
    );
  };

  const renderHistoricalTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tournament</TableCell>
            <TableCell>Rank</TableCell>
            <TableCell>Total Points</TableCell>
            <TableCell>Round Wins</TableCell>
            <TableCell>Expert Countries</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historicalData.map((tournament) => (
            <TableRow key={tournament.tournament_name}>
              <TableCell>{tournament.tournament_name}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {tournament.is_over && getMedalEmoji(tournament.rank)}
                <b>{tournament.rank}.</b>
                <small>(/{tournament.total_players})</small>
              </TableCell>
              <TableCell>{tournament.total_points}</TableCell>
              <TableCell>
                {" "}
                {tournament.round_win_count > 0 &&
                  renderPredefinedEmoji({
                    emojiKey: "roundWinner",
                    count: tournament.round_win_count,
                    customTooltip: `${tournament.round_win_count} Round${
                      tournament.round_win_count > 1 ? "s" : ""
                    } Won`,
                  })}
              </TableCell>
              <TableCell>
                {" "}
                {renderCountryEmojis(tournament.expert_countries)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayName = profileData.user.name || "";
  const title = isCurrentUser
    ? `${greeting}, ${displayName}`
    : `${displayName}${
        displayName.match(/\p{Letter}$/u) &&
        displayName.slice(-1).toLocaleLowerCase("de-DE") === "s"
          ? "'"
          : "'s"
      } Predictions`;

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to deactivate your account?")) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user/delete/${user.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to deactivate account");
        }

        alert("Your account has been deactivated successfully.");
        logout();
        navigate("/");
      } catch (error) {
        console.error("Error deactivating account:", error);
        alert(
          "An error occurred while deactivating your account. Please try again."
        );
      }
    }
  };
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 flex items-center">
        {isCurrentUser ? (
          <>
            {greeting},{" "}
            {isEditing ? (
              <>
                <TextField
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  size="small"
                  error={!!nameError}
                  helperText={nameError}
                />
                <Button onClick={handleNameEdit} className="ml-2">
                  Save
                </Button>
              </>
            ) : (
              <>
                <span className="underline dotted">
                  {profileData.user.name}
                </span>
                <Button onClick={handleNameEdit} className="ml-2">
                  <EditIcon fontSize="small" />
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <span>{title}</span>{" "}
            {!isCurrentUser &&
              getCurrentTournamentStatus !== null &&
              !getCurrentTournamentStatus && (
                <GiantEmoji
                  onClick={handleGiveCroissant}
                  role="button"
                  aria-label="Give croissant"
                  title="Give a croissant anonymously"
                >
                  🥐
                </GiantEmoji>
              )}
          </>
        )}
      </h2>
      {isCurrentUser && croissantCount > 0 && tournamentId === 1 && (
        <div className="mt-4 p-4 bg-yellow-100 rounded-lg">
          <p className="text-lg">
            You have been gifted {croissantCount} croissant
            {croissantCount !== 1 ? "s" : ""} anonymously. Félicitations! 🥐
          </p>
        </div>
      )}
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Date</TableCell>
                <TableCell rowSpan={2}>Game</TableCell>
                <TableCell rowSpan={2}></TableCell>{" "}
                {/* New column for double points indicator */}
                <TableCell rowSpan={2}>Total Points</TableCell>
                <TableCell colSpan={2}>Winner</TableCell>
                <TableCell colSpan={2}>Handicap</TableCell>
                <TableCell colSpan={2}>Top Scorer</TableCell>
                <TableCell rowSpan={2}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedGames).map(([round, predictions]) => (
                <React.Fragment key={round}>
                  <TableRow>
                    <TableCell colSpan={11} className="font-bold bg-gray-100">
                      <b>{round}</b>
                    </TableCell>
                  </TableRow>
                  {predictions.map(renderPredictionRow)}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <h3 className="text-xl font-bold mt-8 mb-4">
        Historical Tournament Results
      </h3>
      <div className="table-container">{renderHistoricalTable()}</div>
      <br></br>
      {isCurrentUser && (
        <Box mt={4}>
          <Button variant="outlined" onClick={logout}>
            Sign out
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteAccount}
            startIcon={<DeleteIcon />}
            sx={{ ml: 2.5 }}
          >
            Deactivate Account
          </Button>
        </Box>
      )}
    </div>
  );
};

export default UserProfile;
